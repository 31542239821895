let Ticker = {
    init: () => {
        handleTickerText();
    }
}

const handleTickerText = () => {
    const messages = [
        "$ 1,200 invoiced from 🇧🇷 <span class='fw-extra-bold'>São Paulo</span> to 🇵🇹 <span class='fw-extra-bold'>Lisbon</span>",
        "€ 600 received from 🇬🇷 <span class='fw-extra-bold'>Athens</span> to 🇨🇾 <span class='fw-extra-bold'>Nicosia</span>",
        "€ 2,300 invoiced from 🇪🇸 <span class='fw-extra-bold'>Madrid</span> to 🇩🇪 <span class='fw-extra-bold'>Berlin</span>",
        "€ 700 received from 🇦🇷 <span class='fw-extra-bold'>Buenos Aires</span> to 🇪🇸 <span class='fw-extra-bold'>Madrid</span>",
        "£ 8,000 invoiced from 🇬🇷 <span class='fw-extra-bold'>Athens</span> to 🇬🇧 <span class='fw-extra-bold'>London</span>",
        "€ 11,400 received from 🇪🇸 <span class='fw-extra-bold'>Madrid</span> to 🇫🇷 <span class='fw-extra-bold'>Paris</span>",
        "$ 900 invoiced from 🇪🇬 <span class='fw-extra-bold'>Cairo</span> to 🇧🇬 <span class='fw-extra-bold'>Sofia</span>",
        "€ 2,000 received from 🇵🇹 <span class='fw-extra-bold'>Lisbon</span> to 🇩🇪 <span class='fw-extra-bold'>Berlin</span>",
        "€ 1,100 invoiced from 🇹🇳 <span class='fw-extra-bold'>Tunis</span> to 🇫🇷 <span class='fw-extra-bold'>Paris</span>",
        "€ 3,500 received from 🇪🇸 <span class='fw-extra-bold'>Madrid</span> to 🇸🇪 <span class='fw-extra-bold'>Stockholm</span>",
        "€ 12,800 invoiced from 🇬🇷 <span class='fw-extra-bold'>Athens</span> to 🇩🇪 <span class='fw-extra-bold'>Berlin</span>",
        "€ 700 received from 🇺🇦 <span class='fw-extra-bold'>Kyiv</span> to 🇵🇱 <span class='fw-extra-bold'>Warsaw</span>",
        "£ 2,100 invoiced from 🇭🇺 <span class='fw-extra-bold'>Budapest</span> to 🇬🇧 <span class='fw-extra-bold'>London</span>",
        "€ 9,300 received from 🇷🇴 <span class='fw-extra-bold'>Bucharest</span> to 🇩🇪 <span class='fw-extra-bold'>Berlin</span>",
        "$ 13,800 invoiced from 🇬🇷 <span class='fw-extra-bold'>Athens</span> to 🇺🇸 <span class='fw-extra-bold'>New York</span>",
        "€ 1,500 received from 🇮🇹 <span class='fw-extra-bold'>Rome</span> to 🇫🇷 <span class='fw-extra-bold'>Paris</span>",
        "€ 2,500 invoiced from 🇬🇧 <span class='fw-extra-bold'>London</span> to 🇳🇱 <span class='fw-extra-bold'>Amsterdam</span>",
        "€ 900 received from 🇹🇭 <span class='fw-extra-bold'>Bangkok</span> to 🇩🇪 <span class='fw-extra-bold'>Berlin</span>",
        "€ 800 invoiced from 🇵🇹 <span class='fw-extra-bold'>Lisbon</span> to 🇳🇱 <span class='fw-extra-bold'>Amsterdam</span>",
        "£ 7,400 received from 🇪🇸 <span class='fw-extra-bold'>Barcelona</span> to 🇬🇧 <span class='fw-extra-bold'>London</span>",
        "€ 1,700 invoiced from 🇬🇷 <span class='fw-extra-bold'>Athens</span> to 🇷🇴 <span class='fw-extra-bold'>Bucharest</span>",
        "€ 600 received from 🇩🇪 <span class='fw-extra-bold'>Berlin</span> to 🇳🇱 <span class='fw-extra-bold'>Amsterdam</span>",
        "€ 1,300 invoiced from 🇵🇱 <span class='fw-extra-bold'>Warsaw</span> to 🇧🇪 <span class='fw-extra-bold'>Brussels</span>",
        "£ 5,200 received from 🇬🇧 <span class='fw-extra-bold'>London</span> to 🇩🇪 <span class='fw-extra-bold'>Berlin</span>",
        "€ 2,700 invoiced from 🇹🇷 <span class='fw-extra-bold'>Istanbul</span> to 🇳🇱 <span class='fw-extra-bold'>Amsterdam</span>",
        "€ 1,000 received from 🇪🇸 <span class='fw-extra-bold'>Madrid</span> to 🇮🇹 <span class='fw-extra-bold'>Rome</span>",
        "€ 5,600 invoiced from 🇭🇷 <span class='fw-extra-bold'>Zagreb</span> to 🇩🇪 <span class='fw-extra-bold'>Berlin</span>",
        "€ 2,400 received from 🇧🇪 <span class='fw-extra-bold'>Brussels</span> to 🇫🇷 <span class='fw-extra-bold'>Paris</span>",
        "€ 6,000 invoiced from 🇪🇸 <span class='fw-extra-bold'>Madrid</span> to 🇫🇮 <span class='fw-extra-bold'>Helsinki</span>",
        "€ 500 received from 🇭🇺 <span class='fw-extra-bold'>Budapest</span> to 🇩🇪 <span class='fw-extra-bold'>Berlin</span>",
        "$ 9,800 invoiced from 🇪🇸 <span class='fw-extra-bold'>Madrid</span> to 🇺🇸 <span class='fw-extra-bold'>New York</span>",
        "€ 2,200 received from 🇳🇱 <span class='fw-extra-bold'>Amsterdam</span> to 🇬🇷 <span class='fw-extra-bold'>Athens</span>",
        "€ 700 invoiced from 🇭🇺 <span class='fw-extra-bold'>Budapest</span> to 🇧🇪 <span class='fw-extra-bold'>Brussels</span>",
        "€ 4,300 received from 🇨🇭 <span class='fw-extra-bold'>Zurich</span> to 🇩🇪 <span class='fw-extra-bold'>Berlin</span>",
        "€ 3,000 invoiced from 🇸🇰 <span class='fw-extra-bold'>Bratislava</span> to 🇦🇹 <span class='fw-extra-bold'>Vienna</span>",
        "€ 6,200 received from 🇵🇹 <span class='fw-extra-bold'>Lisbon</span> to 🇪🇸 <span class='fw-extra-bold'>Madrid</span>",
        "€ 4,800 invoiced from 🇵🇹 <span class='fw-extra-bold'>Lisbon</span> to 🇫🇷 <span class='fw-extra-bold'>Paris</span>",
        "€ 700 received from 🇧🇬 <span class='fw-extra-bold'>Sofia</span> to 🇩🇪 <span class='fw-extra-bold'>Berlin</span>",
        "€ 12,500 invoiced from 🇮🇹 <span class='fw-extra-bold'>Rome</span> to 🇩🇪 <span class='fw-extra-bold'>Berlin</span>",
        "€ 900 received from 🇵🇱 <span class='fw-extra-bold'>Warsaw</span> to 🇫🇷 <span class='fw-extra-bold'>Paris</span>",
        "£ 6,000 invoiced from 🇬🇷 <span class='fw-extra-bold'>Athens</span> to 🇳🇱 <span class='fw-extra-bold'>Amsterdam</span>",
        "€ 2,800 received from 🇮🇹 <span class='fw-extra-bold'>Rome</span> to 🇷🇴 <span class='fw-extra-bold'>Bucharest</span>",
        "€ 13,800 invoiced from 🇧🇪 <span class='fw-extra-bold'>Brussels</span> to 🇩🇪 <span class='fw-extra-bold'>Berlin</span>",
        "€ 1,400 received from 🇧🇬 <span class='fw-extra-bold'>Sofia</span> to 🇪🇸 <span class='fw-extra-bold'>Madrid</span>",
        "€ 2,000 invoiced from 🇬🇧 <span class='fw-extra-bold'>London</span> to 🇷🇴 <span class='fw-extra-bold'>Bucharest</span>",
        "£ 4,100 received from 🇮🇹 <span class='fw-extra-bold'>Rome</span> to 🇳🇱 <span class='fw-extra-bold'>Amsterdam</span>",
        "£ 8,500 invoiced from 🇵🇹 <span class='fw-extra-bold'>Lisbon</span> to 🇬🇧 <span class='fw-extra-bold'>London</span>"
    ];
    let index = 0;
    const tickerElement = document.getElementById("ticker");

    if (tickerElement) {
        function updateTicker() {
            tickerElement.style.opacity = 0;
            setTimeout(() => {
                tickerElement.innerHTML = messages[index];
                index = (index + 1) % messages.length;
                tickerElement.style.opacity = 1;
            }, 1000);
        }
        setInterval(updateTicker, 2500);
    }
}


export default Ticker;
