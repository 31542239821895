let Countdown = {
    init: () => {
        const countdownElement = document.querySelector("[data-countdown]");
        if (!countdownElement ) return;

        const targetDateString = countdownElement.getAttribute("data-countdown");
        const targetDate = parseDate(targetDateString);

        if (!targetDate) {
            console.error("Invalid date format. Use DD-MM-YYYY.");
            return;
        }

        updateCountdown(targetDate, countdownElement);
        setInterval(() => updateCountdown(targetDate, countdownElement), 1000);
    }
};

const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("-").map(Number);
    return new Date(year, month - 1, day);
}

const updateCountdown = (targetDate, element) => {
    const now = new Date();
    const timeRemaining = targetDate - now;

    if (timeRemaining <= 0) {
        element.textContent = "Countdown finished!";
        return;
    }

    const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    element.textContent = `${days}d ${hours}h ${minutes}m ${seconds}s`;
}

export default Countdown;
