import Cookies from 'js-cookie';

let CookieMsg = {
    init: () => {
        handleCookieMsg();
        handleBannerMsg();
        handleDialogMsg();
        sendCookie();
    }
}

const handleCookieMsg = () => {
    let cookieMsg = $('[data-bind="cookiemessage"]'),
        cookieMsgSubmit = cookieMsg.find('[data-bind="submit"]'),
        cookieMsgShowMore = cookieMsg.find('[data-bind="learn-more"]'),
        cookieMsgShowLess = cookieMsg.find('[data-bind="show-less"]'),
        acceptAllBtn = cookieMsg.find('[data-bind="accept-all"]'),
        rejectNonEssentialBtn = cookieMsg.find('[data-bind="reject-non-essential"]');

    cookieMsgSubmit.on('click', function(e) {
        e.stopPropagation();
        cookieMsg.addClass('hidden');
    });

    acceptAllBtn.on('click', function (e) {
        e.stopPropagation();
        cookieMsg.addClass('hidden');
    });

    rejectNonEssentialBtn.on('click', function (e) {
        e.stopPropagation();
        cookieMsg.addClass('hidden');
    });

    function infoToggle() {
        $('#learn-more-content').toggle();
        $('#default-cookie-content').toggle();
    }

    cookieMsgShowMore.add(cookieMsgShowLess).on('click', () => infoToggle());

    typeof Cookies.get('cookieconsent') === 'undefined' ? cookieMsg.removeClass('hidden') : cookieMsg.addClass('hidden');
}

const handleBannerMsg = () => {
    let bannerMsg =  $('[data-bind="bannermessage"]'),
        bannerMsgClose = bannerMsg.find('[data-bind="close"]'),
        navContainer = $('.nav-container'),
        headerBanner = $('.header-banner'),
        carouselBanner = $('.carousel-banner'),
        sectionHeader = $('.section-header'),
        sideBar = $('.nav-sidebar');

    bannerMsgClose.on('click', function(e) {
        e.stopPropagation();
        Cookies.set('bannermessage', 'accepted', {expires: 365});
        navContainer.addClass('no-banner');
        sideBar.removeClass('with-banner');
        sectionHeader.removeClass('section-header-with-banner');
        headerBanner.remove();
        carouselBanner.remove();
    });

    Cookies.get('bannermessage') === 'accepted' ? bannerMsg.remove() : '';

    if ($(".header-banner")[0] || $(".carousel-banner")[0]){
        navContainer.removeClass('no-banner');
        sectionHeader.addClass('section-header-with-banner');
    } else {
        sectionHeader.removeClass('section-header-with-banner');
    }

    navContainer.hasClass('no-banner') ? sideBar.removeClass('with-banner') : '';
}

const sendCookie = () => {
    let cookie = {
        necessaryCookies: $('.necessary-cookies:checked').val(),
        performanceAnalyticsCookies: $('.performance-analytics-cookies:checked').val(),
        advertisementTargetingCookies: $('.advertisement-targeting-cookies:checked').val(),
    }

    function updateCookieValues() {
        cookie.performanceAnalyticsCookies = $('.performance-analytics-cookies:checked').val();
        cookie.advertisementTargetingCookies = $('.advertisement-targeting-cookies:checked').val();
    }

    $('.cookie-checkbox').on('change', () => updateCookieValues());

    $('.cookie-compliance-form').on('submit', function (e) {
        e.stopImmediatePropagation();
        saveCookieConsent();
        return false;
    });

    function saveCookieConsent() {
        document.cookie = 'cookieconsent=' + JSON.stringify(cookie)
            + '; Expires=' + new Date(Date.now() + 12 * 30 * 60 * 60 * 24 * 1000).toUTCString()
            + '; Domain=' + location.hostname.replace('www.', '').replace('app.', '')
            + '; Path=/; SameSite=Strict; Secure';
        window.dataLayer = window.dataLayer || [];
        let consentUpdateEvent = copyOptOutValues(cookie, {
            'event': 'gtm_cookie_consent',
            'ad_storage': cookie.advertisementTargetingCookies ? 'granted' : 'denied',
            'analytics_storage': cookie.performanceAnalyticsCookies ? 'granted' : 'denied'
        });
        window.dataLayer.push(consentUpdateEvent);

        window.dispatchEvent(new Event('cookieConsentUpdated'));
    }

    $('[data-bind="accept-all"]').on('click', function () {
        $('.advertisement-targeting-cookies, .performance-analytics-cookies').prop('checked', true);

        cookie.performanceAnalyticsCookies = 'agree-performance-analytics-cookies';
        cookie.advertisementTargetingCookies = 'agree-advertisement-targeting-cookies';

        saveCookieConsent();
        return false;
    });

    $('[data-bind="reject-non-essential"]').on('click', function () {
        $('.advertisement-targeting-cookies, .performance-analytics-cookies').prop('checked', false);

        cookie.performanceAnalyticsCookies = null;
        cookie.advertisementTargetingCookies = null;

        saveCookieConsent();
        return false;
    });
}

const handleDialogMsg = () => {
    let dialog = $('.dialog-modal'),
        dialogClose = $('[data-dialog-close]');

    dialog.each(function() {
        let cookieName = this.dataset.cookiename;

        Cookies.get(cookieName) ? this.remove() : this.show();
    });


    dialogClose.on('click', function() {
        let dialog = this.closest('.dialog-modal');
        dialog.dataset.cookiename && Cookies.set(dialog.dataset.cookiename, 'accepted', {expires: 365});
        dialog.remove();
    });
}

const copyOptOutValues = (cookiePreferences, consentSettings) => {
    // Disallow opt-out selections, they are present in localStorage only when allowed from CookieMsg module
    if (cookiePreferences.performanceAnalyticsCookies === undefined) {
        consentSettings.analytics_storage = 'denied';
    }
    if (cookiePreferences.advertisementTargetingCookies === undefined) {
        consentSettings.ad_storage = 'denied';
    }

    return consentSettings;
}

export default CookieMsg;
